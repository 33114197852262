<script setup lang="ts">
interface Props {
  datas: any
}

const props = defineProps<Props>()
const isLoading = ref(true)
const companyList = ref()
const loadMore = ref(false)

async function getCompanyList() {
  const { data, error } = await useCompanyApi(`/job/companies/basic?limit=6&sort=random&completePercentageMin=30&showEmployerJobsCount=true&employerJobsCountMin=1&countryCode=${useGetSubdomain()}`, {
    key: 'get_company_list',
    initialCache: false,
  })
  if (data.value)
    companyList.value = data.value
  isLoading.value = false
}
getCompanyList()
</script>

<template>
  <BaseAtomsSkeletonBounded v-if="!isEmpty(companyList)" :class="`${props.datas?.max_width ? props.datas?.max_width : 'px-5 !lg:p-0'}`" class="text-gray-900 grid" container="full">
    <h2 class="text-2xl lg:text-4xl font-bold text-center mb-6">
      {{ props.datas?.title }}
    </h2>

    <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4 lg:gap-6">
      <CompaniesCompanyCardV1 v-for="(companyItem, idx) in !isEmpty(companyList) ? companyList.slice(0, 6) : 6" :key="idx" :show-vacancy="true" :animation="isLoading" :lite="true" :item="companyItem" class="hidden md:block" />
      <CompaniesCompanyCardV1 v-for="(companyItem, idx) in !isEmpty(companyList) ? companyList.slice(0, loadMore ? 6 : 3) : 3" :key="idx" :show-vacancy="true" :animation="isLoading" :lite="true" :item="companyItem" class="block md:hidden" />
    </div>
    <div class="mt-6 md:mt-8 lg:mt-10 <md:grid grid-cols-1 flex">
      <div v-if="!loadMore" class="flex items-center text-sm font-bold text-gray-600 mb-4 mx-auto cursor-pointer md:hidden" @click="loadMore = true">
        <div class="flex flex-shrink-0">
          <Icon name="material-symbols:refresh" class="w-5 h-5 mr-2" />
        </div>
        Load More
      </div>
      <BaseAtomsButton :to="props.datas?.button_url" outline size="sm" class="text-sm font-bold border-[#5843E5] <md:w-full md:px-20 mx-auto">
        {{ props.datas?.button_label }}
      </BaseAtomsButton>
    </div>
  </BaseAtomsSkeletonBounded>
</template>
